/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

const Input = (props) => {
  /*
    Destructures component props, while separating
    all other props into elementProps to be passed
    into dom input elements.
  */
  const {
    label,
    children,
    mask,
    dropdown,
    checkbox,
    textarea,
    containerClassName,
    color,
    ...elementProps
  } = props;

  if (mask) {
    return (
      <div className={`input-container ${containerClassName} ${color ? `wmfab__color--${color}` : ''}`}>
        <MaskedInput mask={mask} {...elementProps} className={color ? `wmfab__color--${color}` : ''} />
        <label htmlFor={elementProps.id} className={color ? `wmfab__color--${color}` : ''}>
          <span>{label}</span>
        </label>
      </div>
    );
  }
  if (dropdown) {
    return (
      <div className={`input-container ${containerClassName} ${color ? `wmfab__color--${color}` : ''}`}>
        <select {...elementProps} className={color ? `wmfab__color--${color}` : ''}>{children}</select>
        <label htmlFor={elementProps.id} className={color ? `wmfab__color--${color}` : ''}>
          <span>{label}</span>
        </label>
      </div>
    );
  }
  if (checkbox) {
    return (
      <div className={`checkbox-container ${containerClassName} ${color ? `wmfab__color--${color}` : ''}`}>
        <input {...elementProps} type="checkbox" />
        <label htmlFor={elementProps.id} className={color ? `wmfab__color--${color}` : ''}>
          <span>{label}</span>
        </label>
      </div>
    );
  }
  if (textarea) {
    return (
      <div className={`input-container ${containerClassName} ${color ? `wmfab__color--${color}` : ''}`}>
        <textarea rows={3} {...elementProps} className={color ? `wmfab__color--${color}` : ''} />
        <label htmlFor={elementProps.id} className={color ? `wmfab__color--${color}` : ''}>
          <span>{label}</span>
        </label>
      </div>
    );
  }
  return (
    <div className={`input-container ${containerClassName} ${color ? `wmfab__color--${color}` : ''}`}>
      <input {...elementProps} className={color ? `wmfab__color--${color}` : ''} />
      <label htmlFor={elementProps.id} className={color ? `wmfab__color--${color}` : ''}>
        <span>{label}</span>
      </label>
    </div>
  );
};

Input.defaultProps = {
  children: null,
  type: null,
  dropdown: null,
  mask: null,
  checkbox: null,
  textarea: null,
  required: false,
  containerClassName: '',
  color: 'white',
};

Input.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
  containerClassName: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.arrayOf(
    PropTypes.element,
  ),
  dropdown: PropTypes.bool,
  checkbox: PropTypes.bool,
  textarea: PropTypes.bool,
  mask: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(RegExp),
        PropTypes.number,
      ]),
    ),
    PropTypes.instanceOf(null),
  ]),
};

export default Input;
