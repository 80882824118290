/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import useInput from '../../hooks/useInput';
import Input from './Input';
import Button from '../Button';
import API_POST from '../../utils/API/POST';
import './Form.scss';

const Form = React.memo(({
  color = "black",
  pathname,
  displayNumber,
  buttonAlign = "right"
}) => {
  const { value: name, bind: bindName, reset: resetName } = useInput('');
  const { value: email, bind: bindEmail, reset: resetEmail } = useInput('');
  const { value: phoneNumber, bind: bindPhoneNumber, reset: resetPhoneNumber } = useInput('');
  const { value: message, bind: bindMessage, reset: resetMessage } = useInput('');

  const [result, setResult] = useState({ disabled: false, message: '', messageType: '' });

  const handleSubmit = (event) => {
    event.preventDefault();
    API_POST({
      name,
      email,
      phoneNumber,
      pathname,
      message,
    },
    (res) => {
      if (res.status === 200) {
        setResult({
          disabled: true,
          message: 'Your message has been sent! If you need to submit another message, please refresh the page. Thank you!',
          messageType: 'success',
        });
        resetName();
        resetEmail();
        resetPhoneNumber();
        resetMessage();
      } else {
        setResult({
          disabled: false,
          message: `There was an error processing your message. Please try again or call us at ${displayNumber}`,
          messageType: 'fail',
        });
      }
    });
  };

  return (
    <form
      onSubmit={(event) => handleSubmit(event)}
      className="wmfab__contact-form"
      autoComplete="off"
      data-netlify="true"
      name="Contact Form"
      method="POST"
    >
      <h2>Contact Us</h2>
      <input type="hidden" name="form-name" value="Contact Form" />
      <Input
        type="text"
        label="Name"
        name="name"
        id="contact-name"
        color={color}
        {...bindName}
        required
        disabled={result.disabled}
      />
      <Input
        type="email"
        label="Email Address"
        name="email"
        id="contact-email"
        {...bindEmail}
        color={color}
        required
        disabled={result.disabled}
      />
      <Input
        type="text"
        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        pattern="[\(]\d{3}[\)]\s\d{3}[\-]\d{4}"
        label="Phone Number"
        name="phoneNumber"
        id="contact-phone-number"
        {...bindPhoneNumber}
        color={color}
        required
        disabled={result.disabled}
      />
      <Input
        textarea
        label="Message"
        name="subject"
        id="contact-message"
        {...bindMessage}
        color={color}
        required
        disabled={result.disabled}
      />

      {/* <ReCAPTCHA
        sitekey="6LdjxbgUAAAAAIS3WiKp8R4KaJyRkXNlZpNyBRUz"
        // onChange={this.handleReCaptchaChange}
      /> */}

      {!result.disabled
        && (
          <div className={`wmfab__button--container-${buttonAlign}`}>
            <Button
              buttonColor="black"
              buttonText="Submit"
              type="submit"
              disabled={result.disabled}
            >
              Submit
            </Button>
          </div>
        )}
      {result.message
        && (
          <span className={`wmfab__form-message small wmfab__form-message-${result.messageType}`}>
            {result.message}
          </span>
        )}
    </form>
  );
});

export default Form;
